<template>
  <div class="page1">
    <!--主体内容- 开具电子发票-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Belonging_operator')" prop="operationId">
                <el-select
                  @change="operationChange(formInline.operationId)"
                  v-model.trim="formInline.operationId"
                  filterable
                  size="15"
                >
                  <el-option label="全部" value></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Channel_type')" prop="channelType">
                <el-select v-model.trim="formInline.channelType">
                  <el-option label="全部" value></el-option>
                  <el-option
                    :label="value.channelTypeDesc"
                    :value="value.channelType"
                    :key="value.channelType"
                    v-for="value in channelList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.plate_number')" prop="plateNumber">
                <el-autocomplete
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model.trim="plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.park_name')">
                <my-component
                  ref="parkInput"
                  :areaIds="formInline.streetId ? formInline.streetId : formInline.areaId"
                  :operationId="formInline.operationId"
                  @valueChange="completeValue"
                  slaveRelations="0,1"
                ></my-component>
              </el-form-item>

              <el-form-item :label="$t('searchModule.Payment_Time')">
                <timeRangePick @timeChange="timeChange" ref="timeRangePicker" />
              </el-form-item>

              <el-form-item :label="$t('searchModule.Operator')">
                <el-input v-model="formInline.operatorName"></el-input>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button type="primary" icon="el-icon-menu" @click="allInsureBilling()"
                >合并开票</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column align="center" :selectable="checkSelectable" type="selection" width="55">
          </el-table-column>
          <el-table-column align="center" type="index" :label="$t('list.index')"></el-table-column>
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          >
          </el-table-column>
          <el-table-column align="center" label="操作" width="130">
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="billing(scope.row)"
                size="mini"
                style="padding: 0 10px 0 0"
                :disabled="scope.row.operateStatus == 1"
              >
                {{ scope.row.operateStatus == 1 ? "开票中" : "开票" }}
                <!-- {{scope.row.operateStatusName}} -->
              </el-button>
              <el-button type="text" style="padding: 0" @click="gopayDetail(scope.row)" size="mini"
                >支付详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 开票弹窗 -->
    <el-dialog title="开具电子发票" :visible.sync="dialogVisible" width="680px">
      <el-form
        label-width="100px"
        :inline="true"
        :model="formBilling"
        class="demo-form-inline"
        :rules="rules"
        ref="formInline"
      >
        <el-form-item prop="email" label="电子邮箱">
          <el-input v-model="formBilling.email" placeholder="请输入电子邮箱"></el-input>
        </el-form-item>
        <el-form-item prop="enterpriseType" :label="$t('searchModule.Invoice_type')">
          <el-select
            style="width: 207px"
            v-model="formBilling.enterpriseType"
            placeholder="请选择发票类型"
          >
            <el-option label="企业" value="1"></el-option>
            <!-- <el-option label="机关事业单位" value="2"></el-option> -->
            <el-option label="个人" value="3"></el-option>
            <!-- <el-option label="其他" value="4"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item prop="payerCompany" :label="$t('searchModule.Invoice_header')">
          <el-input v-model="formBilling.payerCompany" placeholder="请输入发票抬头"></el-input>
        </el-form-item>
        <el-form-item prop="payerTaxpayerNumber" label="纳税人识别号">
          <el-input
            v-model="formBilling.payerTaxpayerNumber"
            placeholder="请输入发票抬头"
            maxlength="18"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('searchModule.address')">
          <el-input v-model="formBilling.address" placeholder="请输入地址"></el-input>
        </el-form-item>
        <el-form-item label="电话">
          <el-input v-model="formBilling.phoneNumber" placeholder="请输入电话"></el-input>
        </el-form-item>
        <el-form-item label="开户行">
          <el-input v-model="formBilling.bankName" placeholder="请输入开户行"></el-input>
        </el-form-item>
        <el-form-item :label="$t('searchModule.account_number')">
          <el-input v-model="formBilling.BankAccount" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item label="发票内容"
          ><div style="width: 207px">{{ formBilling.invoiceContent }}</div>
        </el-form-item>
        <el-form-item label="发票金额">
          <div style="width: 207px">{{ formBilling.billingFee }}元</div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="insureBilling">确 认</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import timeRangePick from "@/components/timePicker";
import myComponent from "@/components/autocomplete/myautoComponent";
import { dateFormat, setIndex } from "@/common/js/public.js";
import exportFile from "@/components/exportFile/exportFile.vue";

export default {
  name: "invoiceOperate",
  data() {
    return {
      multipleSelection: [],
      formBilling: {
        orderIds: "",
        orderType: "",
        enterpriseType: "",
        payerTaxpayerNumber: "",
        payerCompany: "",
        invoiceContent: "停车费",
        billingFee: "0.01",
        email: "",
        address: "",
        phoneNumber: "",
        bankName: "",
        BankAccount: "",
      },
      rules: {
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
        enterpriseType: [{ required: true, message: "请输入发票类型", trigger: "blur" }],
        payerCompany: [{ required: true, message: "请输入发票类型", trigger: "blur" }],
      },
      dialogVisible: false,
      index: 0,
      total: 0,
      pageSize: 15,
      pageNum: 1,

      plateNumber: "",
      loading: false,
      tenantList: [],
      channelList: [],

      tableCols: [
        // {
        //   prop: "cityName",
        //   label: this.$t("list.city"),
        //   width: "",
        // },
        {
          prop: "areaName",
          label: this.$t("list.region"),
        },
        {
          prop: "operationName",
          label: this.$t("list.Belonging_operator"),
        },
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
        },
        {
          prop: "money",
          label: "开票金额",
          width: "",
          formatter: (row, column) => {
            if (row.money) {
              return Number(row.money / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "channelTypeDesc",
          label: "渠道类型",
          width: "",
        },
        {
          prop: "businessTypeDesc",
          label: "业务类型",
          width: "",
        },
        {
          prop: "devTypeDesc",
          label: this.$t("list.Payment_devices"),
          width: "",
        },
        {
          prop: "operatorName",
          label: this.$t("list.Operator"),
          width: "",
        },
        {
          prop: "orderEndTime",
          label: this.$t("list.payment_time"),
          width: "",
        },
      ],
      tableData: [],
      formInline: {
        orderType: "",
        operationId: "",
        channelType: "",
        carId: "",
        parkId: "",
        orderStartTime: "",
        orderEndTime: "",
        operatorName: "", // 操作人
      },
    };
  },
  watch: {
    plateNumber(newVal) {
      if (!newVal) {
        this.formInline.carId = "";
      }
    },
  },
  methods: {
    checkSelectable(row) {
      return row.operateStatus === 0;
    },
    // 支付详情
    gopayDetail(row) {
      this.$router.push({
        name: "parkingPaymentOrder",
        query: {
          orderId: row.orderId,
        },
      });
    },
    // 合并开票
    allInsureBilling() {
      if (this.multipleSelection.length == 0) {
        this.$alert("请至少选择一项开票信息", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      let orderIds = [];
      let totalbillingFee = 0;
      this.multipleSelection.forEach((item) => {
        orderIds.push(item.orderId);
        totalbillingFee += item.money;
      });
      this.formBilling.orderIds = orderIds.join(",");
      this.formBilling.billingFee = Number(totalbillingFee / 100).toFixed(2);

      this.billing();
    },
    insureBilling() {
      console.log(this.formBilling.orderIds, "this.formBilling.orderIds");
      this.$refs.formInline.validate((valid) => {
        if (valid) {
          // 校验是否可开票
          this.$axios
            .post("/acb/2.0/invoiceOperation/order/check", {
              data: { orderIds: this.formBilling.orderIds },
            })
            .then((res) => {
              this.loading = false;
              if (res.state == 0 && res.value.checkStatus == 1) {
                this.invoiceByBlue();
              } else if (res.state == 0 && res.value.checkStatus == 2) {
                this.$alert(res.value.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        }
      });
    },
    // 开票
    billing(row) {
      this.dialogVisible = true;
      this.formBilling.orderType = "";
      this.formBilling.enterpriseType = "";
      this.formBilling.payerTaxpayerNumber = "";
      this.formBilling.payerCompany = "";
      this.formBilling.invoiceContent = "停车费";
      // this.formBilling.billingFee = "0.01";
      this.formBilling.email = "";
      this.formBilling.address = "";
      this.formBilling.phoneNumber = "";
      this.formBilling.bankName = "";
      this.formBilling.BankAccount = "";
      if (row) {
        this.formBilling.orderIds = row.orderId;
        this.formBilling.billingFee = Number(row.money / 100).toFixed(2);
      }
    },
    invoiceByBlue() {
      this.$axios
        .post("/acb/2.0/invoiceOperation/invoiceByBlue", {
          data: this.formBilling,
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.dialogVisible = false;
            this.multipleSelection = [];
            this.$message({
              message: "操作成功！",
              type: "success",
            });
            this.searchData();
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    operationChange(val) {
      this.formInline.operationId = val;
    },
    getTenantList() {
      this.$axios.get("/acb/2.0/tenantPayment/listAllOperationIds").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 获取渠道列表
    getChannelList() {
      this.$axios.get("/acb/2.0/payOrder/payChannelTypeDictList").then((res) => {
        if (res.state == 0) {
          this.channelList = res.value || [];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    resetForm() {
      this.plateNumber = "";
      this.formInline = {
        areaId: "",
        streetId: "",
        operationId: "",
        parkId: "",
        parkType: "1",
        plateNumber: "",
        entryOrExit: 1,
        startTime: "",
        endTime: "",
        mobile: "",
        channelType: "",
        devType: "",
        // timeConditionType: 1,
        // payType: ''
      };
      this.$refs.timeRangePicker.resetTime();
      this.formInline.parkId = "";
      this.formInline.parkName = "";
      this.$refs.parkInput.setValue();
    },
    handleSelect(item) {
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
      this.pageNum = 1;
      this.searchData();
    },
    querySearchAsync(queryString, cb) {
      this.formInline.carId = "";
      queryString = queryString.replace(/\s/g, "");
      if (queryString.length < 3) {
        cb([]);
        return;
      }
      this.$axios
        .get("/acb/2.0/parkRecord/plateNumber/list", {
          data: {
            part: queryString,
            size: 100,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        });
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        let time = new Date(this.formInline.endTime) - new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        if (time >= 31) {
          this.$alert("查询时间不能大于31天");
          return false;
        } else {
          return true;
        }
      }
      return true;
      // else {
      //   this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
      //     confirmButtonText: this.$t('pop_up.Determine'),
      //   });
      //   return false;
      // }
    },

    searchData() {
      let flag = this.showLog();
      if (!flag) return;
      let opt = {
        method: "post",
        url: "/acb/2.0/invoiceOrder/queryList",
        data: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          operationId: this.formInline.operationId,
          channelType: this.formInline.channelType,
          carId: this.formInline.carId,
          parkId: this.formInline.parkId,
          orderStartTime: this.formInline.startTime,
          orderEndTime: this.formInline.endTime,
          operatorName: this.formInline.operatorName, // 操作人
        },
        success: (res) => {
          this.tableData = [];
          this.loading = false;
          if (res.state == 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      if (flag) {
        this.loading = true;
        this.$request(opt);
      }
    },
  },
  components: {
    myComponent,
    timeRangePick,
  },
  activated() {
    this.searchData();
  },
  created() {
    if (this.$route.params.tradeNo) {
      this.formInline.tradeNo = this.$route.params.tradeNo;
    }
  },
  mounted() {
    this.getTenantList();
    this.getChannelList();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  overflow: hidden;



  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.orderNumberStyle {
  color: #0f6eff;
}
</style>
